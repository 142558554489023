import React, { useEffect, useState } from "react";
import axios from "axios";
import BreadCrum from "../../components/BreadCrum";
import { Link } from "react-router-dom";
import Background1 from "../../assets/images/breadcum/01.jpg";

import img from "../../assets/Upload/MediaUploadFile/80435bcdc04249319b80f5fd1ee18a38.png";

const LearningSystem = () => {
  const [mlsData, setMlsData] = useState([]);
  const [dataDesc, setData] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/auth/get/getAllCMSMaster`
      );
      const data = res.data.filter(
        (entry) => entry._id === "6622415878e9ff45f394af02"
      );
      setData(data[0].Description);
      setTitle(data[0].Title);

      console.log(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/api/auth/AllMlsmaster`
        );
        let data = res.data;
  
        // Sorting logic for Beginners and Nursery
        const beginnersId = "66221a293b2aa1719d8421c2";
        const nurseryId = "6717672faedcb8d47a4f5e78";
  
        // Find the Beginners and Nursery items
        const beginnersItem = data.find(item => item._id === beginnersId);
        const nurseryItem = data.find(item => item._id === nurseryId);
  
        // Remove them from the original array if they exist
        data = data.filter(item => item._id !== beginnersId && item._id !== nurseryId);
  
        // Insert Beginners first and Nursery second
        const sortedData = [beginnersItem, nurseryItem, ...data];
  
        // Set the sorted data
        setMlsData(sortedData);
      } catch (error) {
        console.error("Error fetching MLS data:", error);
      }
    };
    fetchData();
  }, []);
  

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await axios.get(
  //         `${process.env.REACT_APP_BASE_API_URL}/api/auth/AllMlsmaster`
  //       );

  //       // Filter out the data with the specific ID
  //       const filteredData = res.data.filter(
  //         (item) => item._id !== "66221a293b2aa1719d8421c2"
  //       );

  //       setMlsData(filteredData);
  //     } catch (error) {
  //       console.error("Error fetching MLS data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <React.Fragment>
      <section
        className="inner-banner bg-overlay-black-70 bg-holder"
        style={{ backgroundImage: `url(${Background1})` }}
      >
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12">
              <div className="text-center">
                <h1 className="text-white">The Clay Learning System</h1>
              </div>
              <div className="d-flex justify-content-center learning-system">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">Academics</a>
                  </li>
                  <li className="breadcrumb-item active">
                    <span>The Clay Learning System</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="research-development-section">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-8">
              <h2 className="text-center text-primary2">{title}</h2>
              {/* Render MLS data */}
              <div className="reseach-development-img">
                {React.createElement("div", {
                  dangerouslySetInnerHTML: { __html: dataDesc },
                })}
              </div>
            </div>
            <div className="col-xl-12 col-lg-12">
              {/* Render MLS data */}

              <div className="mt-5 mb-5 box-odd">
                <div className="row">
                  <div className="col-lg-12 d-flex align-items-center">
                    <div className="content-section">
                      <p className="text-justify">
                        The Clay Learning System is a unique educational
                        approach that sets Clay World School apart. It is
                        designed to foster a love for learning, encourage
                        curiosity, and develop the critical thinking skills
                        necessary for success in the 21st century. The key
                        elements of the Clay Learning System include:
                        Personalized Learning: We recognize that every child is
                        unique. Our personalized learning plans ensure that each
                        student receives the attention and support they need to
                        excel. Interdisciplinary Curriculum: Our curriculum
                        breaks down the traditional barriers between subjects,
                        encouraging students to make connections and explore
                        topics from multiple perspectives. Experiential
                        Learning: We believe that learning happens best when
                        students are actively engaged. Through hands-on
                        projects, field trips, and real-world experiences, we
                        bring learning to life. Technology Integration: We
                        harness the power of technology to enhance learning,
                        using digital tools to create interactive and immersive
                        educational experiences. Global Perspectives: Our
                        curriculum is infused with global perspectives,
                        preparing students to thrive in a rapidly changing world
                        and to become informed, responsible global citizens.
                        Ethical & Environmental Responsibility: We instill a
                        strong sense of ethics and environmental responsibility,
                        teaching students the importance of making a positive
                        impact on society and the planet. The Clay Learning
                        System is more than just an educational framework; it is
                        a philosophy that shapes every aspect of our school,
                        ensuring that our students are well-prepared for the
                        challenges and opportunities of the future.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {mlsData.map((item, index) =>
                index % 2 === 0 ? (
                  <div key={index} className="mt-5 mb-5 box-odd">
                    <div className="row">
                      <div className="col-lg-6 d-flex align-items-center">
                        <div className="content-section">
                          <h4 className="text-primary2">{item.Title}</h4>
                          <p className="text-justify">{item.description}</p>
                        </div>
                      </div>
                      <div className="col-lg-6 d-flex align-items-center">
                        <div className="img-section">
                          <img
                            className="img-fluid"
                            src={`${process.env.REACT_APP_BASE_API_URL}/${item.MLSImage}`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div key={index} className="mt-5 mb-5 box-odd">
                    <div className="row">
                      <div className="col-lg-6 d-flex align-items-center">
                        <div className="img-section">
                          <img
                            className="img-fluid"
                            src={`${process.env.REACT_APP_BASE_API_URL}/${item.MLSImage}`}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 d-flex align-items-center">
                        <div className="content-section">
                          <h4 className="text-primary2">{item.Title}</h4>
                          <p className="text-justify">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default LearningSystem;
